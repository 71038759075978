import { Alert, Button, Container, Grid, Link, Snackbar, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link as ReactRouterLink, useHistory } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { DepartmentsContext, UserContext } from "../../components/PrivateRoute";
import { DIRECTOR } from "../../data/Roles";
import { pastStaffYearsSince2021 } from "../../helpers/FormatDate";
import { Path } from "../../helpers/Path";
import { InitMessageState, MessageState } from "../../models/MessageState";
import { Reimbursement } from "../../models/Reimbursement";
import { InitRequestsModel, Request, RequestsModel } from "../../models/Request";
import { RequestDeclined } from "../../models/RequestDeclined";
import { Staff } from "../../models/Staff";
import { pay } from "../../services/reimbursements";
import {
  approve,
  cancel,
  convertToRequestsModel,
  decline,
  getBudgetManagerRequests,
  getDirectorRequests,
  getFinanceHeadRequests,
  getHODRequests,
  getMyRequests,
} from "../../services/requests";
import { ReimbursementPayConfirmationDialog } from "./ReimbursementPayConfirmationDialog";
import { ReimbursementsSubmit } from "./ReimbursementsSubmit";
import { RequestsDeclineConfirmationDialog } from "./RequestsDeclineConfirmationDialog";
import { RequestsHowDialog } from "./RequestsHowDialog";
import { RequestsList } from "./RequestsList";

import { ConfirmationModal } from "../../components/ConfirmationModal";
import { RequestsSubmit } from "./RequestsSubmit";

export interface RequestsInterface {
  loading: boolean;
  requests: RequestsModel;
  alert: MessageState;
  howDialogOpen: boolean;
  postDialogOpen: boolean;
  declineDialogOpen: boolean;
  reimbursementDialogOpen: boolean;
  cancelRequestDialogOpen: boolean;
  reimbursementConfirmationDialogOpen: boolean;
  currentRequest?: Request;
  currentRequestType?: keyof RequestsModel;
  currentName?: string;
}

export const Requests = () => {
  const staff = useContext(UserContext) as Staff;
  const departments = useContext(DepartmentsContext);

  const [state, setState] = useState<RequestsInterface>({
    loading: true,
    requests: InitRequestsModel,
    alert: InitMessageState,
    howDialogOpen: false,
    declineDialogOpen: false,
    postDialogOpen: false,
    reimbursementDialogOpen: false,
    cancelRequestDialogOpen: false,
    reimbursementConfirmationDialogOpen: false,
  });

  const history = useHistory();

  useEffect(() => {
    const getMyRequestsFetch = getMyRequests(staff);
    const getHODRequestsFetch = getHODRequests(staff, departments);
    const getBudgetManagerRequestsFetch = getBudgetManagerRequests(staff, departments);
    const getFinanceHeadRequestsFetch = getFinanceHeadRequests(staff);
    const getDirectorRequestsFetch = getDirectorRequests(staff);
    Promise.all([
      getMyRequestsFetch,
      getHODRequestsFetch,
      getBudgetManagerRequestsFetch,
      getFinanceHeadRequestsFetch,
      getDirectorRequestsFetch,
    ]).then(([myRequests, hodRequests, budgetmanagerRequests, financeHeadRequests, directorRequests]) =>
      setState({
        ...state,
        requests: convertToRequestsModel(
          myRequests,
          hodRequests,
          budgetmanagerRequests,
          financeHeadRequests,
          directorRequests
        ),
        loading: false,
      })
    );

    // eslint-disable-next-line
  }, []);

  const {
    loading,
    requests,
    alert,
    howDialogOpen,
    postDialogOpen,
    declineDialogOpen,
    reimbursementDialogOpen,
    cancelRequestDialogOpen,
    reimbursementConfirmationDialogOpen,
    currentRequest,
    currentRequestType,
    currentName,
  } = state;

  const cancelRequest = () =>
    currentRequest &&
    cancel(currentRequest)
      .then((cancelledRequest) => {
        setState({
          ...state,
          requests: {
            ...requests,
            myRequests: {
              ...requests.myRequests,
              pending: requests.myRequests.pending.filter((r) => r.id !== cancelledRequest.id),
              approved: requests.myRequests.approved.filter((r) => r.id !== cancelledRequest.id),
            },
          },
          alert: {
            showMessage: true,
            message: "Successfully cancelled request!",
            severity: "success",
          },
          cancelRequestDialogOpen: false,
          currentRequest: undefined,
        });
      })
      .catch((error) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: error.message,
            severity: "error",
          },
          cancelRequestDialogOpen: false,
          currentRequest: undefined,
        })
      );

  const approveRequest = (requestApproved: Request, requestType: keyof RequestsModel, name: string) =>
    approve(requestApproved, `approvedBy${name}`, departments)
      .then((approvedRequest) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: "Successfully approved request!",
            severity: "success",
          },
          requests: {
            ...requests,
            [requestType]: {
              ...requests[requestType],
              pending: requests[requestType].pending.filter((r) => r.id !== approvedRequest.id),
              approved: [...requests[requestType].approved, approvedRequest],
            },
          },
          currentRequest: undefined,
        })
      )
      .catch((error) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: error.message,
            severity: "error",
          },
          currentRequest: undefined,
        })
      );

  const declineRequest = (requestDeclined: RequestDeclined, requestType: keyof RequestsModel, name: string) =>
    decline(requestDeclined, `approvedBy${name}`)
      .then((declinedRequest) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message:
              "Successfully declined. An email has been sent to " +
              declinedRequest.staff.firstName +
              " notifying that you have declined the request.",
            severity: "success",
          },
          declineDialogOpen: false,
          requests: {
            ...requests,
            [requestType]: {
              ...requests[requestType],
              pending: requests[requestType].pending.filter((r) => r.id !== declinedRequest.id),
              completed: [...requests[requestType].approved, declinedRequest],
            },
          },
          currentRequest: undefined,
          currentRequestType: undefined,
          currentName: undefined,
        })
      )
      .catch((error) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: error.message,
            severity: "error",
          },
          declineDialogOpen: false,
          currentRequest: undefined,
          currentRequestType: undefined,
          currentName: undefined,
        })
      );

  const payReimbursement = (reimbursement: Reimbursement, requestType: keyof RequestsModel) =>
    pay(reimbursement)
      .then((paidRequest) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: "Successfully paid! An email has been sent to the user",
            severity: "success",
          },
          reimbursementConfirmationDialogOpen: false,
          requests: {
            ...requests,
            [requestType]: {
              ...requests[requestType],
              pending: requests[requestType].pending.filter((r) => r.id !== paidRequest.id),
              completed: [...requests[requestType].approved, paidRequest],
            },
          },
          currentRequest: undefined,
          currentRequestType: undefined,
          currentName: undefined,
        })
      )
      .catch((error) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: error.message,
            severity: "error",
          },
          reimbursementConfirmationDialogOpen: false,
          currentRequest: undefined,
          currentRequestType: undefined,
          currentName: undefined,
        })
      );

  return loading ? (
    <Loading />
  ) : (
    <>
      <Snackbar
        open={alert.showMessage}
        onClose={() =>
          setState({
            ...state,
            alert: { ...alert, showMessage: false },
          })
        }
        autoHideDuration={6000}
      >
        <Alert elevation={6} variant="filled" severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
      <RequestsSubmit
        staff={staff}
        dialogOpen={postDialogOpen}
        closeDialog={() => setState({ ...state, postDialogOpen: false })}
        handlePost={(alertState, request) =>
          request != null &&
          setState({
            ...state,
            postDialogOpen: false,
            alert: alertState,
            requests: {
              ...requests,
              myRequests: {
                ...requests.myRequests,
                pending: [...requests.myRequests.pending, request],
              },
            },
          })
        }
      />
      {currentRequest && (
        <>
          {currentRequestType && currentName && (
            <>
              <ReimbursementPayConfirmationDialog
                currReimbursement={currentRequest as Reimbursement}
                dialogOpen={reimbursementConfirmationDialogOpen}
                closeDialog={() =>
                  setState({
                    ...state,
                    reimbursementConfirmationDialogOpen: false,
                  })
                }
                payReimbursement={(reimbursement: Reimbursement) => payReimbursement(reimbursement, currentRequestType)}
              />
              <RequestsDeclineConfirmationDialog
                currRequest={currentRequest as Request}
                dialogOpen={declineDialogOpen}
                closeDialog={() => setState({ ...state, declineDialogOpen: false })}
                declineRequest={(request) => declineRequest(request, currentRequestType, currentName)}
              />
            </>
          )}
          <ConfirmationModal
            title={"Cancel Request?"}
            content={"This will cancel the request."}
            confirmText={"Yes"}
            cancelText={"No"}
            dialogOpen={cancelRequestDialogOpen}
            closeDialog={() => setState({ ...state, cancelRequestDialogOpen: false })}
            doAction={cancelRequest}
          />
          <ReimbursementsSubmit
            currRequest={currentRequest}
            dialogOpen={reimbursementDialogOpen}
            closeDialog={() => setState({ ...state, reimbursementDialogOpen: false })}
            handlePost={(alertState, reimbursement) =>
              setState({
                ...state,
                reimbursementDialogOpen: false,
                alert: alertState,
                requests: {
                  ...requests,
                  myRequests: {
                    ...requests.myRequests,
                    approved: reimbursement
                      ? [reimbursement as Request].concat(
                          requests.myRequests.approved.filter((r) => reimbursement.id !== r.id)
                        )
                      : requests.myRequests.approved,
                  },
                },
              })
            }
          />
        </>
      )}
      <Container maxWidth="lg">
        {staff.department === departments.FINANCE && (
          <Grid container justifyContent="flex-end">
            <Button onClick={() => history.push(Path["Set Budget Manager"])} variant="contained" size="small">
              Set budget manager for this/next year
            </Button>
          </Grid>
        )}
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Requests</Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => setState({ ...state, howDialogOpen: true })}>
              How?
            </Button>
            <RequestsHowDialog
              dialogOpen={howDialogOpen}
              handleClose={() => setState({ ...state, howDialogOpen: false })}
            />
            <Button variant="contained" onClick={() => setState({ ...state, postDialogOpen: true })}>
              Make Request
            </Button>
          </Grid>
        </Grid>
        <RequestsList
          title="Your Requests"
          requests={requests.myRequests}
          staff={staff}
          cancelRequest={(request) =>
            setState({
              ...state,
              currentRequest: request,
              cancelRequestDialogOpen: true,
            })
          }
          submitReimbursement={(request) =>
            setState({
              ...state,
              currentRequest: request,
              reimbursementDialogOpen: true,
            })
          }
        />
        {Object.entries(departments.departments).find(
          ([d, v]) => v.head.id === staff.id && d !== departments.FINANCE
        ) && (
          <RequestsList
            title="HOD Requests"
            requests={requests.hodRequests}
            staff={staff}
            approve={(request) => approveRequest(request, "hodRequests", "HOD")}
            decline={(request) =>
              setState({
                ...state,
                currentRequest: request,
                currentRequestType: "hodRequests",
                currentName: "HOD",
                declineDialogOpen: true,
              })
            }
          />
        )}
        {staff.id === departments.BUDGET_MANAGER && (
          <RequestsList
            title="Budget Manager Requests"
            requests={requests.budgetManagerRequests}
            staff={staff}
            approve={(request) => approveRequest(request, "budgetManagerRequests", "Finance")}
            decline={(request) =>
              setState({
                ...state,
                currentRequest: request,
                currentRequestType: "budgetManagerRequests",
                currentName: "Finance",
                declineDialogOpen: true,
              })
            }
          />
        )}
        {staff.role === DIRECTOR && (
          <RequestsList
            title="Director Requests"
            requests={requests.directorRequests}
            staff={staff}
            approve={(request) => approveRequest(request, "directorRequests", DIRECTOR)}
            decline={(request) =>
              setState({
                ...state,
                currentRequest: request,
                currentRequestType: "directorRequests",
                currentName: DIRECTOR,
                declineDialogOpen: true,
              })
            }
          />
        )}
        {"jnotpNRVKOW6pfoEgr3fwKSvpaV2" === staff.id && (
          <RequestsList
            title="Finance Head Requests"
            requests={requests.financeHeadRequests}
            staff={staff}
            approve={(request) => approveRequest(request, "financeHeadRequests", "FinanceHead")}
            decline={(request) =>
              setState({
                ...state,
                currentRequest: request,
                currentRequestType: "financeHeadRequests",
                currentName: "FinanceHead",
                declineDialogOpen: true,
              })
            }
            pay={(request) =>
              setState({
                ...state,
                currentRequest: request,
                currentRequestType: "hodRequests",
                currentName: "HOD",
                reimbursementConfirmationDialogOpen: true,
              })
            }
          />
        )}
        {staff.department === departments.FINANCE && <Typography>History</Typography>}
        {staff.department === departments.FINANCE && (
          <Grid container direction="column">
            {pastStaffYearsSince2021.map((y, i) => (
              <Grid item key={i}>
                <Link to={Path["Past Year's Requests"] + "/" + y} component={ReactRouterLink}>
                  {y}
                </Link>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </>
  );
};
